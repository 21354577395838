<template>
  <div>
    <router-view />
    <v-btn plain></v-btn>
    <v-dialog persistent v-model="wrong_role" width="50%" >
      <v-card height="180">
        <v-card-title class="text-h5 red">
          Error!
        </v-card-title>
        
        <v-card-text class="text-subtitle-1"><v-icon x-large color="red">mdi-alert-octagon</v-icon>
          Usted no cuenta con los permisos necesarios para acceder a esta función...
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row align="center" justify="center">
          <v-btn color="primary" text @click="close_alert">
            Continuar
          </v-btn></v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
export default {
name: "admin",
    data: () => ({
      wrong_role: false,
    }),
    methods: {
        close_alert(){
            this.$router.push('/home')
        }
    },
mounted(){
  let user = JSON.parse(sessionStorage.getItem('user'))
  if (!user){
    this.$router.push('/login');
  }else{
    const verr = user.roles.includes('ROLE_SUPERVISOR')||user.roles.includes('ROLE_HYPERVISOR')
    if (verr == false) [
        this.wrong_role = true,
        
    ]
  }
}
}
</script>